<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3'
import Checkbox from '@/Components/Checkbox.vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/InputError.vue'
import InputLabel from '@/Components/InputLabel.vue'
import PrimaryButton from '@/Components/PrimaryButton.vue'
import TextInput from '@/Components/TextInput.vue'

defineProps({
	canResetPassword: {
		type: Boolean,
	},
	status: {
		type: String,
	},
})

const form = useForm({
	email: '',
	password: '',
	remember: false,
	accept: false,
})

const submit = () => {
	form.post(route('login'), {
		onSuccess: () => form.reset('password'),
	})
}

const isProduction = import.meta.env.VITE_APP_ENV === 'production'// TODO: temp fix for hiding register link
</script>

<template>
	<GuestLayout>
		<Head title="Login" />

		<div
			v-if="status"
			class="mb-4 font-medium text-sm text-green-600"
		>
			{{ status }}
		</div>

		<form @submit.prevent="submit">
			<div>
				<InputLabel
					for="email"
					value="E-mail"
				/>

				<TextInput
					id="email"
					v-model="form.email"
					type="email"
					class="mt-1 block w-full"
					required
					autofocus
					autocomplete="username"
				/>

				<InputError
					class="mt-2"
					:message="form.errors.email"
				/>
			</div>

			<div class="mt-4">
				<InputLabel
					for="password"
					value="Wachtwoord"
				/>

				<TextInput
					id="password"
					v-model="form.password"
					type="password"
					class="mt-1 block w-full"
					required
					autocomplete="current-password"
				/>

				<InputError
					class="mt-2"
					:message="form.errors.password"
				/>
			</div>

			<div class="block mt-4">
				<label class="flex items-center">
					<Checkbox
						v-model:checked="form.remember"
						name="remember"
					/>
					<span class="ml-2 text-sm text-gray-600 select-none">Onthouden</span>
				</label>
			</div>

			<div class="block mt-4">
				<label class="flex items-center">
					<Checkbox
						v-model:checked="form.accept"
						name="accept"
					/>
					<span class="ml-2 text-sm text-gray-600 select-none">Ik ga akkoord met de  <a
						href="https://studentenbedrijf.nl/algemene-voorwaarden/"
						target="_blank"
						class="underline"
					>voorwaarden</a>.</span>
				</label>
				<InputError
					class="mt-2"
					:message="form.errors.accept"
				/>
			</div>

			<div class="flex items-center justify-end mt-4">
				<Link
					v-if="canResetPassword"
					:href="route('password.request')"
					class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
				>
					Wachtwoord vergeten?
				</Link>

				<PrimaryButton
					class="ml-4"
					:class="{ 'opacity-25': form.processing }"
					:disabled="form.processing"
				>
					Login
				</PrimaryButton>
			</div>
		</form>

		<template
			v-if="!isProduction"
			#bottom
		>
			<p class="mt-10 text-center text-sm text-gray-500">
				Geen account?
				{{ ' ' }}
				<Link
					:href="route('register.user.create')"
					class="font-semibold leading-6 text-primary-600 hover:text-primary-500"
				>
					Registeren
				</Link>
			</p>
		</template>
	</GuestLayout>
</template>
